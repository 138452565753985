var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project_check_list"},[_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('z-table',{ref:"table",attrs:{"fullscreenLoading":""},on:{"query":_vm.queryList},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":"","header-cell-style":_vm.rowClass}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"typeName","label":"认证类型"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"联系人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.userName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"desc","label":"职位信息"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"view-city"},[(row.company)?_c('span',[_vm._v(_vm._s(row.company))]):_vm._e(),(row.job)?_c('span',[_vm._v(_vm._s(row.job))]):_vm._e(),(row.jobLevel)?_c('span',[_vm._v(_vm._s(row.jobLevel))]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"desc","label":"详情信息"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type == 1)?_c('div',[_c('p',[_vm._v("身份类型："+_vm._s(row.investorType))]),_c('p',[_vm._v("关注领域："+_vm._s(row.investField.toString()))]),_c('p',[_vm._v("偏好轮次："+_vm._s(row.investTurn.toString()))]),_c('p',[_vm._v("偏好地区："+_vm._s(row.investArea.toString()))])]):_vm._e(),(row.type == 2)?_c('div',[_c('div',[_vm._v(" 所在地区： "),_c('div',{staticClass:"view-city",staticStyle:{"display":"inline-block"}},[(row.province)?_c('span',[_vm._v(_vm._s(row.province))]):_vm._e(),(row.city)?_c('span',[_vm._v(_vm._s(row.city))]):_vm._e()])]),_c('p',[_vm._v("公司简介："+_vm._s(row.companyDesc))])]):_vm._e(),(row.type == 3)?_c('div',[_c('p',[_vm._v("机构类型："+_vm._s(row.agencyType))]),_c('p',[_vm._v("机构名称："+_vm._s(row.agencyName))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"businessCard","label":"名片"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p-image',{attrs:{"src":row.businessCard}})]}}])}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"认证时间"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-popconfirm',{attrs:{"title":"是否继续操作"},on:{"confirm":function($event){return _vm.approved(row, 0)}}},[_c('el-button',{attrs:{"slot":"reference","type":"text","icon":"el-icon-s-check","size":"mini"},slot:"reference"},[_vm._v("审核通过")])],1),_c('el-button',{staticStyle:{"color":"red"},attrs:{"slot":"reference","type":"text","icon":"el-icon-s-check","size":"mini"},on:{"click":function($event){return _vm.auditNotThrough(row)}},slot:"reference"},[_vm._v("审核不通过")]),_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.onDelete(row)}}},[_vm._v("删除")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.btn_if.indexOf('finance_view_contact') != -1),expression:"$store.state.btn_if.indexOf('finance_view_contact') != -1"}],staticClass:"green",attrs:{"type":"text","icon":"el-icon-camera","size":"mini"},on:{"click":function($event){return _vm.$refs.link.getFinance(row.userId)}}},[_vm._v("查看联系方式")])]}}])})],1)],1)],1),_c('Slink',{ref:"link",attrs:{"isView":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }