<template>
    <div class="project_check_list">
        <!-- <el-card>
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="请输入项目名称" />
            </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
            </nav>
        </el-card> -->
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                    <el-table-column prop="id" label="ID"> </el-table-column>
                    <el-table-column prop="typeName" label="认证类型"></el-table-column>
                    <el-table-column prop="userName" label="联系人">
                        <template #default="{row}">
                            <div>
                                {{ row.userName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc" label="职位信息">
                        <template #default="{row}">
                            <div class="view-city">
                                <span v-if="row.company">{{ row.company }}</span>
                                <span v-if="row.job">{{ row.job }}</span>
                                <span v-if="row.jobLevel">{{ row.jobLevel }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc" label="详情信息">
                        <template #default="{row}">
                            <div v-if="row.type == 1">
                                <p>身份类型：{{ row.investorType }}</p>
                                <p>关注领域：{{ row.investField.toString() }}</p>
                                <p>偏好轮次：{{ row.investTurn.toString() }}</p>
                                <p>偏好地区：{{ row.investArea.toString() }}</p>
                            </div>
                            <div v-if="row.type == 2">
                                <div>
                                    所在地区：
                                    <div class="view-city" style="display: inline-block;">
                                        <span v-if="row.province">{{ row.province }}</span>
                                        <span v-if="row.city">{{ row.city }}</span>
                                    </div>
                                </div>
                                <p>公司简介：{{ row.companyDesc }}</p>
                            </div>
                            <div v-if="row.type == 3">
                                <p>机构类型：{{ row.agencyType }}</p>
                                <p>机构名称：{{ row.agencyName }}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="businessCard" label="名片">
                        <template slot-scope="{ row }">
                            <p-image :src="row.businessCard"></p-image>
                        </template>
                    </el-table-column>

                    <el-table-column prop="updateTime" label="认证时间"> </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <el-popconfirm @confirm="approved(row, 0)" title="是否继续操作">
                                <el-button slot="reference" type="text" icon="el-icon-s-check" size="mini"
                                    >审核通过</el-button
                                >
                            </el-popconfirm>
                            <el-button
                                @click="auditNotThrough(row)"
                                slot="reference"
                                type="text"
                                icon="el-icon-s-check"
                                style="color: red;"
                                size="mini"
                                >审核不通过</el-button
                            >
                            <el-button
                                @click="onDelete(row)"
                                type="text"
                                icon="el-icon-delete"
                                style="color: red;"
                                size="mini"
                                >删除</el-button
                            >
                            <el-button
                                v-show="$store.state.btn_if.indexOf('finance_view_contact') != -1"
                                type="text"
                                icon="el-icon-camera"
                                size="mini"
                                class="green"
                                @click="$refs.link.getFinance(row.userId)"
                                >查看联系方式</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </z-table>
        </el-card>
        <Slink isView ref="link" />
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                keyword: "",
            },
        };
    },
    activated() {
        // console.log(1);
    },
    components: {
        Slink: () => import("@/components/seeTheLink.vue"),
    },
    methods: {
        getlist() {
            this.$refs.table.refresh();
        },

        async queryList(val) {
            let { data: res } = await this.$http.get("/admin/Finance/getUserCertList", {
                params: { ...val, ...this.search },
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                this.$refs.table.complete(res.data);
            }
        },
        async onDelete(row) {
            await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            let { data: res } = await this.$http.post("/admin/Finance/delCert", { id: row.id });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        async approved(row) {
            let { data: res } = await this.$http.post("/admin/Finance/userCert", { id: row.id, status: 1 });
            if (res.errorCode == 200) {
                this.$refs.table.refresh();
            }
        },
        async auditNotThrough(row) {
            var res = await this.$prompt("请输入不通过原因", "提示");
            var { data: res } = await this.$http.post("/admin/Finance/userCert", {
                id: row.id,
                status: 2,
                failReason: res.value,
            });
            this.$refs.table.refresh();
        },

        Ftag(val) {
            if (val == 0) {
                return "待审核";
            }
            if (val == 1) {
                return "审核通过";
            }
            if (val == 2) {
                return "审核不通过";
            }
            return "";
        },
    },
};
</script>

<style lang="less" scoped>
.tag {
    display: inline-block;
    margin: 5px;
}
</style>
